@use "mixins";
@use "@angular/material" as mat;

@include mat.elevation-classes();
@include mat.app-background();

body {
  // Цветовая палитра
  --black: #050609;

  --grey-dark: #2c2c2c;
  --grey: #878787;
  --grey-light: #dedede;
  --grey-disabled: #f2f2f2;
  --grey1: #fbfdf9;
  --grey2: #f3f3f3;
  --grey3: #f9f9f9;
  --grey4: #757575;
  --grey5: #b8b8b8;
  --grey6: #d9d9d9;
  --grey7: #efefef;
  --grey8: #ebebeb;

  --sky: #edf5ff;
  --sky-dark: #e2eefe;
  --sky-light: rgba(238, 245, 254, 0.8);
  --sky-super-light: #eef5fe;

  --blue: #629ee4;
  --blue1: #274b75;
  --blue2: #153356;

  --green-base: #6f9305;
  --green: #7a8449;
  --green-light: #cee3af;
  --green-super-light: #dbe9c6;

  --green-light-backround: rgba(219, 233, 198, 0.4);

  --elevator-background: #3c3e32;
  --elevator-background-light: #54564b;

  --yellow: #f3ef7a;
  --yellow-dark: #efeb76;

  --white: #ffffff;

  --divider: #efefef;

  --red: #fff3f4;
  --red2: #ff5b65;

  // Базовый значения
  --border-radius: 16px;
  --border-radius-button: 12px;
  --border-radius-mobile: 8px;
  --border-radius-secondary: 32px;

  --content-width: 1356px;

  --header-height: 162px;
  --header-mobile-height: 62px;

  --footer-height: 288px;
  --footer-adaptive-height: 499px;
  --footer-mobile-height: 70px;

  --map-height: calc(100dvh - var(--header-height) - 30px);
  --min-map-height: 800px;

  margin: 0;
  padding: 0;

  background-color: var(--white);
}

html * {
  box-sizing: border-box;

  font-family: "Montserrat", sans-serif;
  color: var(--black);
  letter-spacing: normal;
  @include mixins.Base_Text;

  -webkit-tap-highlight-color: transparent;

  .radio-label * {
    font-size: 16px;
  }

  .checkbox-label {
    padding-top: 11px;
    font-size: 14px;
    text-transform: uppercase;
  }

  .checkbox-label * {
    font-size: 14px;
    text-transform: uppercase;
  }

  // todo могут понадобиться при доработках на странице аналитики
  // .checkbox-analytics {
  //   padding-top: 11px;
  //   font-size: 16px;
  //   text-transform: uppercase;
  // }

  // .checkbox-analytics * {
  //   font-size: 16px;
  //   text-transform: uppercase;
  // }
}

:root {
  scroll-behavior: smooth;

  .grecaptcha-badge {
    display: none;
  }

  .mdc-text-field {
    --mdc-filled-text-field-container-color: var(--white);
    --mdc-filled-text-field-hover-active-indicator-color: var(--grey-light);
    --mdc-filled-text-field-active-indicator-color: var(--grey-light);
    --mdc-filled-text-field-focus-active-indicator-color: var(--grey-light);
  }

  .mat-mdc-form-field-focus-overlay {
    background-color: transparent;
  }

  mat-label {
    font-size: 14px;
    color: var(--grey);
  }

  .mat-mdc-option {
    --mat-option-label-text-color: var(--black);
    --mat-option-label-text-font: "Montserrat", sans-serif;
    --mat-option-label-text-line-height: normal;
    --mat-option-label-text-size: 16px;
    --mat-option-label-text-tracking: normal;
    --mat-option-label-text-weight: 500;
    --mat-option-focus-state-layer-color: var(--grey7);
  }

  .mdc-list-item__primary-text {
    width: 100%;
  }

  .mat-mdc-checkbox {
    &.priority {
      width: 75%;
      cursor: pointer;
    }

    .mat-internal-form-field {
      align-items: flex-start;
    }
  }

  .mdc-checkbox {
    --mdc-checkbox-selected-icon-color: var(--green-base);
    --mdc-checkbox-selected-checkmark-color: var(--white);
    --mdc-checkbox-selected-focus-icon-color: var(--green-base);
    --mdc-checkbox-selected-hover-icon-color: var(--green-base);
    --mdc-checkbox-selected-focus-state-layer-color: var(--green-base);
    --mdc-checkbox-selected-hover-state-layer-color: var(--green-base);
    --mdc-checkbox-unselected-pressed-state-layer-color: var(--green-base);
    --mat-checkbox-disabled-label-color: var(--grey);
    --mdc-checkbox-disabled-unselected-icon-color: var(--grey-light);

    * {
      color: var(--grey-light);
    }
  }

  .mdc-checkbox__background {
    border: 1px solid var(--grey-light);
    border-radius: 5px;
    width: 22px;
    height: 22px;

    top: calc((40px - 22px) / 2);
    left: calc((40px - 22px) / 2);

    svg {
      width: 15px;
      height: 15px;

      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .mdc-checkbox__mixedmark {
    width: 70%;
  }

  .mdc-radio {
    --mdc-radio-selected-focus-icon-color: var(--green-base);
    --mdc-radio-selected-hover-icon-color: var(--green-base);
    --mdc-radio-selected-icon-color: var(--green-base);
    --mdc-radio-selected-pressed-icon-color: var(--green-base);
    --mat-radio-checked-ripple-color: var(--green-base);
    --mdc-radio-unselected-icon-color: var(--grey-light);
  }

  .mat-mdc-select-panel {
    --mat-select-panel-background-color: var(--white);
    --mat-option-hover-state-layer-color: var(--grey3);
    --mat-option-selected-state-layer-color: var(--grey7);
    --mat-select-container-elevation-shadow: 0px 9px 20px 0px rgba(5, 6, 9, 0.04),
    0px 5.833px 11.713px 0px rgba(5, 6, 9, 0.03),
    0px 3.467px 6.37px 0px rgba(5, 6, 9, 0.02),
    0px 1.8px 3.25px 0px rgba(5, 6, 9, 0.02),
    0px 0.733px 1.63px 0px rgba(5, 6, 9, 0.02),
    0px 0.167px 0.787px 0px rgba(5, 6, 9, 0.01);

    border-radius: var(--border-radius-mobile);
  }

  .mat-mdc-select-placeholder {
    text-transform: initial;
    --mat-select-placeholder-text-color: var(--grey);
  }

  .mat-mdc-autocomplete-panel {
    --mat-autocomplete-background-color: var(--white);
    --mat-autocomplete-container-shape: var(--border-radius-mobile);
    --mat-autocomplete-container-elevation-shadow: 0px 9px 20px 0px rgba(5, 6, 9, 0.04),
    0px 5.833px 11.713px 0px rgba(5, 6, 9, 0.03),
    0px 3.467px 6.37px 0px rgba(5, 6, 9, 0.02),
    0px 1.8px 3.25px 0px rgba(5, 6, 9, 0.02),
    0px 0.733px 1.63px 0px rgba(5, 6, 9, 0.02),
    0px 0.167px 0.787px 0px rgba(5, 6, 9, 0.01);
  }

  .mat-mdc-dialog-surface {
    --mdc-dialog-container-shape: var(--border-radius);
  }

  .mat-mdc-dialog-content {
    --mdc-dialog-supporting-text-color: var(--black);
    --mdc-dialog-supporting-text-font: "Montserrat", sans-serif;
  }

  .mat-expansion-panel {
    --mat-expansion-container-shape: 0;
    --mat-expansion-header-hover-state-layer-color: var(--white);
    --mat-expansion-header-expanded-state-height: 48px;

    box-shadow: none !important;
    background: none;
    border: none;

    .mat-expansion-panel-header {
      padding: 0 12px;

      &.cereals-panel-header {
        padding: 0;
      }

      &.analytics-cereals-header {
        padding: 0;
        font-size: 16px;
      }

      .mat-expansion-indicator {
        width: 24px;
        height: 24px;

        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &.analytics-cereals-panel {
      .mat-expansion-panel-body {
        padding: 0;
      }
    }

    &.categories-panel {
      border-bottom: 1px solid var(--grey7);

      .mat-expansion-panel-body {
        padding: 0 12px 16px;
      }
    }

    &.cereals-panel {
      border-bottom: 1px solid var(--grey7);

      &.last-cereal {
        border-bottom: none;
      }

      margin-left: 30px;

      .mat-expansion-panel-body {
        padding: 0;
      }
    }

    &.category-filter-checkboxes {
      .mat-expansion-panel-body {
        padding: 0 16px 0 0;
      }
    }

    &.category-filter-numbers {
      .mat-expansion-panel-body {
        padding: 0 12px 0;
      }
    }

    &.price-analysis-panel {
      .mat-expansion-panel-body {
        padding: 0;
      }
    }
  }

  .cdk-overlay-dark-backdrop {
    background: rgba(33, 33, 33, 0.2);
  }

  .datapicker {
    .mat-mdc-text-field-wrapper.mdc-text-field {
      border-radius: var(--border-radius-mobile);
      border: 1px solid var(--grey-light);
      background: var(--white);

      .mat-mdc-form-field-infix {
        padding-top: 12px;
        padding-bottom: 12px;
        --mat-form-field-container-height: 46px;

        .mat-date-range-input-wrapper {
          font-size: 16px;
        }
      }

      .mat-mdc-button-touch-target {
        height: 46px;
      }
    }

    .mat-mdc-form-field-icon-suffix,
    .mat-mdc-form-field-icon-prefix {
      padding: 0 4px 0 4px;
    }

    .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
      display: none;
    }

    .mdc-line-ripple {
      display: none;
    }

    &.error {
      .mat-mdc-text-field-wrapper.mdc-text-field {
        border-color: var(--red2);
      }
    }
  }

  .mat-datepicker-content {
    --mat-datepicker-calendar-container-background-color: var(--white);
    --mat-datepicker-calendar-container-shape: var(--border-radius-mobile);

    --mat-datepicker-calendar-date-selected-state-background-color: var(
        --green-base
    );
    --mat-datepicker-calendar-date-today-selected-state-outline-color: var(
        --green-base
    );
    --mat-datepicker-calendar-date-in-range-state-background-color: var(
        --green-super-light
    );

    border: 1px solid var(--grey5);
    box-shadow: 0 4px 203px 0 rgba(5, 6, 9, 0.04),
    0 2.771px 128.902px 0 rgba(5, 6, 9, 0.03),
    0 1.808px 77.531px 0 rgba(5, 6, 9, 0.03),
    0 1.082px 44.269px 0 rgba(5, 6, 9, 0.02),
    0 0.567px 24.502px 0 rgba(5, 6, 9, 0.02),
    0 0.233px 13.612px 0 rgba(5, 6, 9, 0.01),
    0 0.054px 6.984px 0 rgba(5, 6, 9, 0.01);
  }

  .mat-calendar-content {
    padding: 0 24px 24px 24px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    appearance: textfield;
  }

  input[type="search"]::-ms-clear {
    display: none;
  }

  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  input::placeholder {
    -webkit-font-smoothing: antialiased;
    color: var(--grey);
  }
}

.z-index-1000 {
  z-index: 1000 !important;
}

.disabled-svg {
  path {
    stroke: var(--grey);
  }
}

.simple-link {
  word-break: break-all;
  @include mixins.Text_Thin_Black;
  @include mixins.Simple_Hover_Focus;
}

.select {
  width: 312px !important;

  &.mat-mdc-select {
    .mat-mdc-select-trigger {
      padding: 13px 20px;
      border-radius: 8px;
      border: 1px solid var(--grey-light);
      background: var(--white);

      * {
        font-size: 16px;
        text-transform: uppercase;
      }

      .mat-mdc-select-placeholder {
        text-transform: initial;
      }

      @include mixins.Input_Hover_Focus;
    }

    .mat-mdc-select-arrow-wrapper {
      width: 20px;
      height: 20px;
      background-position: center;
      background-image: url("/assets/icons/chevron-right.svg");

      transform: rotate(360deg);
      transition: transform 0.5s;

      .mat-mdc-select-arrow {
        width: 20px;
        height: 20px;

        svg {
          display: none;
        }
      }
    }
  }

  &.error {
    &.mat-mdc-select {
      .mat-mdc-select-trigger {
        border-color: var(--red2);
      }
    }
  }

  &.opened {
    &.mat-mdc-select {
      .mat-mdc-select-trigger {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border: 1px solid var(--grey5);
      }
    }

    .mat-mdc-select-arrow-wrapper {
      transform: rotate(180deg);
      transition: transform 0.5s;
    }
  }

  &.disabled {
    pointer-events: none;
  }
}

.select.group-by {
  &.mat-mdc-select {
    .mat-mdc-select-trigger {
      * {
        text-transform: none;
      }
    }
  }
}

.select-list {
  margin-top: -1px;
  padding: 16px 12px 20px 12px !important;
  text-transform: uppercase;
  border: 1px solid var(--grey5);
  background: var(--white);

  &.group-by {
    text-transform: none;
  }

  .mat-mdc-optgroup-label {
    @include mixins.Text;
  }

  .empty-list {
    @include mixins.Text;
    padding: 10px 12px;
  }
}

.select-list-option {
  border-radius: var(--border-radius-mobile);

  .mat-pseudo-checkbox {
    display: none;
    border-color: var(--grey-light);
    border-radius: 5px;
    --mat-full-pseudo-checkbox-selected-icon-color: var(--green-base);
  }

  &.offset {
    margin-left: 24px;
  }

  &.multi-with-checkbox {
    .mat-pseudo-checkbox {
      display: inline-block;
    }
  }

  .cereals-divider {
    margin: 7px 0;
    height: 2px;
    background-color: var(--grey8);
  }
}

.autocomplete-address-list {
  padding: 6px 8px 8px 10px !important;
  border: 1px solid var(--grey5);

  margin-top: -1px;

  .autocomplete-address-hint {
    @include mixins.Text;
    padding: 10px 12px;
  }

  .loader-wrapper {
    height: 39px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loader-component {
    width: 20px;
    height: 20px;
  }
}

.autocomplete-address-item {
  padding: 10px 12px !important;
  border: none;
  border-radius: var(--border-radius-mobile);
  text-transform: uppercase;

  .mat-pseudo-checkbox {
    display: none;
  }

  &:hover,
  &:focus-visible {
    background-color: var(--grey3);
  }

  &.empty {
    padding: 0 !important;
    height: 0;
    min-height: 0;
    overflow: hidden;
    pointer-events: none;
  }
}

.snackbar {
  --mdc-snackbar-supporting-text-color: var(--white);
  --mdc-snackbar-container-shape: 8px;

  color: var(--white);
  border-radius: 8px;

  * {
    border-radius: 8px;
    color: var(--white);
  }

  &.success {
    --mdc-snackbar-container-color: var(--green-base);
  }

  &.error {
    --mdc-snackbar-container-color: var(--red2);
  }
}

.recaptcha-block {
  margin: 0 auto;
  height: 74px;

  &.mt-32 {
    margin-top: 32px;
  }
}

.full-screen-form {
  width: 444px;
  margin: 16px 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .title {
    @include mixins.Big_Text;
    margin-bottom: 16px;
  }

  .label {
    @include mixins.Text_Thin;
    margin-bottom: 32px;

    span {
      @include mixins.Base_Bold_Text;
      color: var(--green-base);
    }
  }

  .submit-button {
    display: flex;
    margin-top: 32px;

    &.without-top-margin {
      margin-top: 0;
    }
  }

  .link-block {
    text-align: center;

    @include mixins.Text_Thin;
    color: var(--black);
  }

  .mt-16 {
    margin-top: 16px;
  }

  .mt-24 {
    margin-top: 24px;
  }

  .mt-32 {
    margin-top: 32px;
  }

  .mb-16 {
    margin-bottom: 16px;
  }

  .mb-24 {
    margin-bottom: 24px;
  }

  .mb-32 {
    margin-bottom: 32px;
  }
}

// Формы в разделе профиля
.profile-form {
  hr {
    width: 100%;
    height: 1px;
    background-color: var(--divider);
    border: none;

    margin: 40px 0;

    &.top {
      margin-top: 0;
    }

    &.middle-margin {
      margin-top: 16px;
    }
  }

  .profile-form-item {
    display: flex;
    align-items: flex-start;
    gap: 20px;

    &.confirm {
      margin-top: 40px;
    }

    .description {
      width: 400px;
      min-width: 400px;

      display: flex;
      flex-direction: column;
      gap: 8px;

      .label {
        @include mixins.Base_Text;

        .required {
          color: var(--red2);
        }
      }

      .description-text {
        @include mixins.Text_Thin;
        color: var(--grey);
      }

      .error {
        @include mixins.Text_Thin_Black;
        color: var(--red2);
      }
    }

    .item-input {
      width: 100%;
      max-width: 412px;
    }

    .value {
      width: 100%;

      display: flex;
      flex-direction: column;
      gap: 8px;

      .timer-text {
        @include mixins.Middle_Thin_Text;
        color: var(--grey);
      }

      .timer-button {
        font-size: 14px;

        button {
          font-size: 14px;
        }
      }
    }

    .contacts-block {
      width: 100%;

      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;

      .input-phone {
        width: 100%;
        max-width: 180px;
      }

      .input-code {
        width: 100%;
        max-width: 101px;
      }
    }
  }

  .recaptcha-block {
    margin: 32px 0;
  }
}

// Данные о валидности создаваемого пароля
.password_data {
  margin-top: 8px;
  margin-bottom: 24px;

  .password_data_item {
    @include mixins.Small_Text;
    color: var(--grey);

    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;

    &.done {
      color: var(--green-base);
    }

    &:not(:last-child) {
      margin-bottom: 9px;
    }

    svg {
      width: 16px;
      min-width: 16px;
      height: 16px;
      min-height: 16px;
    }
  }
}

.pages * {
  all: revert;
}

.pages {
  h1,
  h2,
  h3,
  h4 {
    //@include mixins.Big_Text;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  h5 {
    font-weight: 600;
  }

  a {
    text-decoration: none;
    @include mixins.Text;
    color: var(--green-base);

    @include mixins.Simple_Hover_Focus;
  }

  blockquote {
    display: inline-block;
    background-color: var(--grey2);
    padding: 8px;
    border-radius: var(--border-radius-mobile);
  }
}

.not-network-page {
  padding: 48px 16px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;

  .description {
    @include mixins.Big_Text;
    text-align: center;
  }
}

@include mixins.md {
  .profile-form {
    .profile-form-item {
      flex-direction: column;

      .description {
        width: 100%;
        min-width: 100%;
      }
    }
  }

  .not-network-page {
    .external {
      display: none;
    }
  }
}

@include mixins.sm {
  .simple-link {
    font-size: 14px;
  }

  .profile-form {
    hr {
      margin: 24px 0;
    }

    .profile-form-item {
      .description {
        .label {
          font-size: 14px;
        }

        .description-text {
          font-size: 14px;
        }
      }
    }
  }
}

@include mixins.xs {
  :root {
    .mat-mdc-dialog-surface {
      --mdc-dialog-container-shape: 0;
    }

    .mat-mdc-dialog-content {
      max-height: none;
    }
  }

  .mat-expansion-panel {
    &.price-analysis-panel {
      margin: 0 -12px;
    }
  }

  .cdk-overlay-pane.mat-mdc-dialog-panel {
    --mat-dialog-container-small-max-width: 100vw;
    --mat-dialog-container-small-max-height: 100vw;

    width: 100dvw;
    max-width: 100dvw;
    height: 100dvh;
    max-height: 100dvh;
  }

  .datapicker {
    width: 100%;
  }

  .select {
    width: 100% !important;
  }

  .select-list {
    width: 100% !important;
  }

  .profile-form {
    .profile-form-item {
      &.confirm {
        margin-top: 24px;
      }

      .item-input {
        max-width: 100%;
      }

      .contacts-block {
        flex-wrap: wrap;

        .input-code,
        .input-phone {
          max-width: 100%;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .full-screen-form {
    width: 100%;
    min-width: 100%;
  }
}

.b24-form {

  .b24-window-close {
    font-size: 11px;
  }

  .b24-form-header-title {
    @include mixins.Base_Bold_Text;
  }

  .b24-form-content {
    padding-top: 0;
  }

  textarea + .b24-form-control-label {
    opacity: 1;
    background-color: var(--white);
    margin-right: 20px;
  }

  .b24-form-control-select-label, .b24-form-control:focus + .b24-form-control-label, .b24-form-control-not-empty + .b24-form-control-label {
    top: 0;
  }

  .b24-form-header {
    padding-top: 28px;
    border-bottom: none;
    text-align: left;
  }

  .b24-form-btn {
    border-radius: var(--border-radius-button);
    background-color: var(--yellow);
  }

  .b24-window-popup-title {
    white-space: break-spaces;
  }

  @include mixins.sm {
    .b24-window-close {
      opacity: 1;
    }

    .b24-window-scrollable {
      // всё в пол по максимуму
      padding-bottom: 0 !important;
      max-height: 100dvh !important;
    }
  }
}
