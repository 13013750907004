@mixin xxs {
  @media (max-width: 400px) {
    @content;
  }
}

@mixin xs {
  @media (max-width: 600px) {
    @content;
  }
}

@mixin sm {
  @media (max-width: 900px) {
    @content;
  }
}

@mixin not-sm {
  @media (min-width: 901px) {
    @content;
  }
}

@mixin md {
  @media (max-width: 1200px) {
    @content;
  }
}

@mixin adaptive-header {
  @media (max-width: 1400px) {
    @content;
  }
}

@mixin lg {
  @media (max-width: 1536px) {
    @content;
  }
}

@mixin xl {
  @media (max-width: 1700px) {
    @content;
  }
}

@mixin xxl {
  @media (max-width: 1920px) {
    @content;
  }
}

@mixin Title_H1 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  letter-spacing: normal;
}

@mixin Big_Text {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@mixin Base_Text {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@mixin Base_Bold_Text {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@mixin Text {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@mixin Text_Bold {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@mixin Text_Thin {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  color: var(--grey);
}

@mixin Text_Thin_Black {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@mixin Middle_Bold_Text {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@mixin Middle_Text {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@mixin Middle_Thin_Text {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@mixin Small_Text {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@mixin Simple_Hover_Focus {
  &:focus {
    outline: none;
  }

  &:hover,
  &:focus-visible {
    outline: none;
    opacity: 0.7;
  }

  @media (max-width: 900px) {
    &:hover {
      opacity: 1;
    }
  }
}

@mixin Input_Hover_Focus {
  &:hover,
  &:focus,
  &:focus-visible {
    outline: none;
    border: 1px solid var(--grey5);

    &.error {
      border: 1px solid red;
    }
  }
}

@mixin Disabled_Button {
  &:disabled {
    pointer-events: none;
    cursor: default;
    background-color: var(--grey-disabled);
    color: var(--grey);
  }
}
